import './App.css';
import React, { useEffect } from 'react';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Pages/Homepage/homepage';
import Contact from './Pages/Contact';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Aos from 'aos';
import "aos/dist/aos.css";
import './App.css';

function Main() {
  useEffect(() => {
    Aos.init({
      duration: 1200
    });
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/contact' component={Contact}></Route>
      </Switch>
    </div>
  )
}

function App() {
  return (
    <div className="background">
      <BrowserRouter>
        <Header />
         <Main />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
