import img1 from '../img/bag.jpg';
import img2 from '../img/bag2.jpg';
import img3 from '../img/bag3.jpg';
import img4 from '../img/bag4.jpg';
import img5 from '../img/bag5.jpg';
import img6 from '../img/bag6.jpg';
import img7 from '../img/bag7.jpg';
import img8 from '../img/bag8.jpg';

export const Products = [
    {
        id: 0,
        img: img1,
        name:"NCP-ZEN-1025043812991"
    },
    {
        id: 1,
        img: img2,
        name:"NCP/ZEN/102504381258"
    },
    {
        id: 2,
        img: img3,
        name:"NCP/ZEN/102504381258"
    },
    {
        id: 3,
        img: img4,
        name:"NCP/ZEN/11041575"
    },
    {
        id: 4,
        img: img5,
        name:"NCP/ZEN/110413"
    },
    {
        id: 5,
        img: img6,
        name:"NCP/ZEN/150413"
    },
    {
        id: 6,
        img: img7,
        name:"NCP/MDR/142502751025"
    },
    {
        id: 7,
        img: img8,
        name:"PNCP/MDR/08750413"
    },
]