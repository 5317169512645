import React, { Component } from 'react';
import './index.css';

export default class index extends Component {
    render() {
        return (
            <div className="contact">
                <div classNames="font">
                    <div className="container ">
                        <div className=" container mt-4 borders"></div>
                        <h1 className="mt-4 col-12 d-flex justify-content-center">Contact Us</h1>
                        <div className=" container mt-4 borders"></div>
                    </div>
                    <div className="container">
                        <h1 className="mt-4  d-flex justify-content-center">Our Members</h1>
                        <p className="contact_para">Developing writers can often benefit from examining an essay, a paragraph, or even a sentence to determine what makes it effective. On the following pages are several paragraphs for you to evaluate on your own, along with the Writing Center's explanation.

                            Note: These passages are excerpted from actual student papers and retain the original wording. Some of the sentences are imperfect, but we have chosen these passages to highlight areas in which the author was successful
                        </p>
                    </div>
                    <div className="container">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="col-lg-4 col-md-4 col-sm-12" >
                                <div className="card contactbox card-body">
                                    <i className="fa fa-envelope-open-text fa-3x" style={{ color: "#3b3380" }}></i>
                                    <label className="" style={{ color: "#3b3380" }}>How can we help you?</label>
                                    <label className="text-black-50">Send us an email...</label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12" >
                                <div className="card contactbox card-body">
                                    <i className="fa fa-phone-alt fa-3x" style={{ color: "#3b3380" }}></i>
                                    <label className="" style={{ color: "#3b3380" }}>Feel free to get in touch</label>
                                    <label className="text-black-50">+91 (987)-6543-210</label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12" >
                                <div className="card contactbox card-body">
                                    <i className="fa fa-calendar-edit fa-3x" style={{ color: "#3b3380" }}></i>
                                    <label className="" style={{ color: "#3b3380" }}>Ready to request a quote?</label>
                                    <label className="text-black-50">Describe your design</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
