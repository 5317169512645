import React, { Component } from 'react';
import './index.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import logo from '../../img/logo.png';
import Popup from '../PopupPage';
// import Link from 'react-router-dom'
import { Link } from 'react-router-dom';

export default class Header extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            // submenu: false,
            menu: true,
            // visible: false
            popup: false
        }
        this.dropdrownfunc = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.closeDropdown);
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.toggleVisibility);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.closeDropdown);
        window.removeEventListener("scroll", this.handleScroll);
    }


    closeDropdown = (e) => {
        if (this.dropdrownfunc.current && !this.dropdrownfunc.current.contains(e.target)) {
            this.setState({ show: false })
        }
    }

    openPopup = () => {
        this.setState((e) => {
            return {
                popup: !e.popup
            }
        })
    }

    showMenu = () => {
        this.setState((e) => {
            return {
                show: !e.show,
            };
        });
    };

    sendEnquiry() {
        alert('Enquiry Send')
    }

    render() {
        return (
            <div className="header">
                <div className="col-12 text-white row mobileview p-0 m-0">
                    <div className="col-8 ml-4 mt-4">
                        <h4 className="ml-4 pl-4"><i className="pl-4 ml-4 fal fa-phone-alt mr-4"></i>Contact Us : +91 9876543210</h4>
                    </div>
                    <div className="col-3 hicons">
                        {/* <i className="fal fa-cog fa-2x"></i> */}
                        <i className="fab fa-instagram  float-right ml-4 fa-2x"></i>
                        <i className="fab fa-whatsapp  float-right ml-4 fa-2x"></i>
                        <i className="fab fa-facebook-square ml-4 float-right fa-2x"></i>
                        <i className="fal fa-envelope ml-4 float-right fa-2x"></i>
                    </div>
                </div>

                <div className="ncp">
                    <div className="">
                        <div className="d-flex col-sm-12 col-md-12 col-lg-12">
                            <div className="col-sm-4 col-md-4 col-lg-2 menulogo">
                                <img src={logo} className="image"></img>
                            </div>
                            <div className="col-md-8 col-lg-8 menu">
                                <label><Link to="/">Home</Link></label>
                                <label>About us</label>
                                {/* <div  className="product">
                                    <label className="">Products</label>
                                    <div className="">
                                        <a>Eco-friendly Bag</a>
                                        <a>Card Box</a>
                                        <a>White Card Board</a>
                                    </div>
                                </div> */}
                                <div className="dropdown">
                                    <label className="">Products</label>
                                    <div className="dropdown-content">
                                        <a>Eco-friendly Bag</a>
                                        <a>Card Box</a>
                                        <a>White Card Board</a>
                                    </div>
                                </div>
                                {/* <label></label> */}
                                <label>Factory</label>
                                <label>Cart</label>
                                <label>Environment Policy</label>
                                <label><Link to='/contact'>Contact</Link></label>
                            </div>
                            <div className="col-md-2 getquery col-xs-2 col-lg-2 ml-4 pt-4" >
                                <button className="btn-lg btn" style={{ color: "white", backgroundColor: "#3b3380" }} onClick={this.openPopup}>Get Enquiry</button>
                            </div>

                            {/* =================== Mobile Menu start ================== */}
                            <div className="col-xs-6 col-sm-8 col-md-8 mobile-menu">
                                
                                <i className="fa fa-bars fa-2x ml-4 float-right " onClick={this.showMenu}></i>
                                <i className="fab fa-whatsapp ml-4 float-right fa-2x"></i>
                                <i className="fab fa-facebook-square float-right ml-4 fa-2x"></i>
                                <i className="fal fa-envelope ml-4 float-right fa-2x"></i>
                                {
                                    this.state.show &&
                                    <div className="col-lg-12 col-md-12 col-sm-12 dropdownfunc" ref={this.dropdrownfunc}>
                                        <ul>
                                            <li className="text-body"> Home</li>
                                            <li>About Us</li>
                                            <li>Our Presence</li>
                                        </ul>
                                    </div>
                                }
                            </div>

                            {/* ====================== mobile menu end ==================== */}

                        </div>
                    </div>
                </div>




                {
                    this.state.popup &&
                    <Popup handleClick={this.openPopup} content={
                        <div>
                            <div className="head"><label>We will get back to you soon</label></div>
                            <div className="col-12 row form">
                                <div className="col-6">
                                    <h4 className="mt-4" >Name</h4>
                                    <input className="mt-4 form-control form-control-lg" placeholder="Name"></input>
                                </div>
                                <div className="col-6">
                                    <h4 className="mt-4 ml-4" >E-mail</h4>
                                    <input className="mt-4 ml-4 form-control form-control-lg" placeholder="E-mail"></input>
                                </div>
                                <div className="col-6">
                                    <h4 className="mt-4" >Mobile Number</h4>
                                    <input className="mt-4 form-control form-control-lg" placeholder="+91 9876543210"></input>
                                </div>
                                <div className="col-6">
                                    <h4 className="mt-4 ml-4" >Subject</h4>
                                    <input className="mt-4 ml-4 form-control form-control-lg" placeholder="Subject"></input>
                                </div>
                                <div className="col-12 pr-0">
                                    <h4 className="mt-4" >Message</h4>
                                    <textarea className="mt-4 form-control form-control-lg" placeholder="Message..."></textarea>
                                </div>
                                <div className="col-12 mt-4" ><div className="col-2 float-right btn btn-lg" onClick={this.sendEnquiry} style={{ backgroundColor: "#3b3380", color: "white" }}>Send Enquiry</div></div>
                            </div>
                        </div>
                    } ></Popup>
                }



                {/* <div className="ncp">
                    <div className="">
                        <div className="d-flex col-12">
                            <div className="col-2 d-flex justify-content-center">
                                <img src={logo} className="image"></img>
                            </div>
                            <div className="col-8">
                                <nav className="navbtn mt-2">
                                    <a href='/' >Home</a>
                                    <a>About Us</a>
                                    <a >Products</a>
                                    <a>Factory</a>
                                    <a>Cart</a>
                                    <a>Environment Policy</a>
                                    <a href='/contact'>Contact</a>
                                </nav>
                            </div>
                            <div className="col-2 col-md-2 col-xs-2 col-lg-2 ml-4 pt-4" >
                                <button className="btn-lg btn" style={{ color: "white", backgroundColor: "#3b3380" }} onClick={this.openPopup}>Get Enquiry</button>
                            </div>

                            {
                                this.state.popup &&
                                <Popup handleClick={this.openPopup} content={
                                    <div>
                                        <div className="head"><label>We will get back to you soon</label></div>
                                            <div className="col-12 row form">
                                                <div className="col-6">
                                                    <h4 className="mt-4" >Name</h4>
                                                    <input className="mt-4 form-control form-control-lg" placeholder="Name"></input>
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="mt-4 ml-4" >E-mail</h4>
                                                    <input className="mt-4 ml-4 form-control form-control-lg" placeholder="E-mail"></input>
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="mt-4" >Mobile Number</h4>
                                                    <input className="mt-4 form-control form-control-lg" placeholder="+91 9876543210"></input>
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="mt-4 ml-4" >Subject</h4>
                                                    <input className="mt-4 ml-4 form-control form-control-lg" placeholder="Subject"></input>
                                                </div>
                                                <div className="col-12 pr-0">
                                                    <h4 className="mt-4" >Message</h4>
                                                    <textarea className="mt-4 form-control form-control-lg" placeholder="Message..."></textarea>
                                                </div>
                                               <div className="col-12 mt-4" ><div className="col-2 float-right btn btn-lg" style={{backgroundColor:"#3b3380",color:"white"}}>Send Enquiry</div></div>
                                            </div>
                                    </div>
                                } ></Popup>
                            }

                            =================== Mobile Menu start ==================

                            <i className="fa col-4 fa-bars fa-3x mobile-menu" onClick={this.showMenu}></i>
                            {
                                this.state.show &&
                                <div className="col-lg-12 col-md-12 col-sm-12 dropdownfunc" ref={this.dropdrownfunc}>
                                    <ul>
                                        <li className="text-body"> Home</li>
                                        <li>About Us</li>
                                        <li>Our Presence</li>
                                    </ul>
                                </div>
                            }

                            ====================== mobile menu end ====================
                        </div>

                    </div>
                </div> */}

                {/* <div className="d-flex"> */}
                {/* <div className="col-12 mob col-md-12 col-xs-12 col-lg-12"> */}
                {/* <div className="menu col-md-8 col-xs-8 col-lg-8 col-8">

                        <div className="wrap_nav">
                            <nav className="navbtn">
                                <a href='/' data="Home" >Home</a>
                                <a onClick={() => window.scrollTo({ top: 1085, behavior: 'smooth' })} data="About Us">About Us</a>
                                <a onClick={() => window.scrollTo({ top: 660, behavior: 'smooth' })} data="Products">Products</a>
                                <a data="Factory">Factory</a>
                                <a data="Cart">Cart</a>
                                <a data="Environment Policy">Environment Policy</a>
                                <a href='/contact' data="Contact">Contact</a>
                            </nav>
                        </div>
                    </div> */}


                {/* <div className="col-9 col-md-9 col-xs-9 text-white col-lg-9">
                        <nav className="navbtn">
                            <a href='/' data="Home" >Home</a>
                            <a onClick={() => window.scrollTo({ top: 1085, behavior: 'smooth' })} data="About Us">About Us</a>
                            <a onClick={() => window.scrollTo({ top: 660, behavior: 'smooth' })} data="Products">Products</a>
                            <a data="Factory">Factory</a>
                            <a data="Cart">Cart</a>
                            <a data="Environment Policy">Environment Policy</a>
                            <a href='/contact' data="Contact">Contact</a>
                        </nav>
                    </div> */}


                {/* </div> */}
                {/* </div> */}
            </div>
        )
    }
}
