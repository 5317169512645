import React from 'react';
import './index.css';

const Popup = props => {
    return (
        <div className="popup">
            <div className="popupbox">
                <span className="closeicon" onClick={props.handleClick}>X</span>
                {props.content}
            </div>
        </div>
    )
};

export default Popup;