import React, { Component } from 'react';
import './index.css';
import { Products } from '../product'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import Carousal from './carousal';
import Carousal from './carousal'
import ProdCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import rrr from '../../img/rrr.png'
import img1 from '../../img/grbag.jpg';
import aboutwall from '../../img/aboutwall.jpg';
import aboutwall2 from '../../img/aboutwall2.jpg';
import { Link } from 'react-router-dom';
// import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Popup from '../../Component/PopupPage';




export default class homepage extends Component {
    constructor() {
        super();

    }

    render() {

        const responsive = {
            largedesktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4
            },
            desktop: {
                breakpoint: { max: 1024, min: 780 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 780, min: 480 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 480, min: 200 },
                items: 1
            }
        };

        const items = Products.map((list) => {
            return <div key={list.id} className="m-4">
                <div className="col-12 totalbox mt-4" >
                    <div className="card box card-body col-12">
                        <div>
                            <img src={list.img} className="proimg" ></img>
                        </div>
                    </div>
                    <div className="prodfont ">
                        <label className="mt-4 col-12">Buy This Product</label>
                        <a className="btn-lg button">&nbsp;&nbsp; Buy Product  &nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>
        })


        return (
            <div >
                <div style={{ backgroundColor: "" }}>
                    <Carousal />
                </div>

                <div className="App">
                    <div className="">
                        <div className="mt-4 mb-4 col-12 col-md-12 col-xs-12 col-lg-12 row" >
                            <div className="font aboutpic col-md-7 col-xs-7 col-lg-6 col-sm-12">
                                {/* <ScrollAnimation delay={500} animateIn="fadeIn">
                                <div>About Us</div>
                                </ScrollAnimation> */}
                                <img src={aboutwall2} className="aboutwall" ></img>
                                <div><img src={aboutwall} className="aboutwall2"></img></div>
                            </div>
                            <div className="col-md-5 col-xs-4 col-lg-5 col-sm-12 content">
                                <div className="col-12 abouthead">
                                    <ScrollAnimation delay={300} animateIn="fadeIn">
                                        About <span style={{ color: "#3b3380" }}>Us</span>
                                    </ScrollAnimation>
                                </div><br />
                                <div className="col-12 aboutpara"><p>WE HAVE CENTER OF FITNESS MORE THAN 20 YEARS</p></div>
                                <div className="col-12 aboutpara2"><p>Lorem ipsum dolor amet consectetur adipiscing do eiusmod tempor incididunt abore dolore magna ut enim ad minim veniam ut exercitation ullamco commodo consequat incididunt.</p></div>
                                <div className="col-12 cntrbtn"><button className="btn btn-lg col-6 col-xs-12 col-sm-12 getmore">Get more details</button></div>
                            </div>
                            {/* <label>About Us</label> */}
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="container">
                        <div className="font borders col-12 col-md-12 col-xs-12 col-lg-12" >
                            <div>Popular Products</div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <ProdCarousel infinite={true} responsive={responsive}>
                            {items}
                        </ProdCarousel>
                    </div>
                </div>

                <div className="App1 mt-4">
                    <div className="" style={{ padding: "0 5%" }}>
                        <div className="font borders col-12 col-md-12 col-xs-12 col-lg-12" >
                            <div>Our Story</div>
                        </div>
                        <p className="col-md-12 col-xs-12 col-lg-12 para p-0">
                            New Crystal Packagings has vast experience in manufacturing and export of paper shopping bags, paper pick up bags, multi speciality paper bags customized to the needs of individual corporates. Our plant situated at Kovilpalayam, Coimbatore has state of the art facility for manufacture of plain, fussed and many other varieties of paper bags. The promoters and key management personnel have more than 30 years of professional work experience in their respective fields.
                        </p>
                        <div className="col-md-12 col-sm-12 contacts col-xs-12 col-lg-12 row p-0">
                            <div className="col-md-4 boxheight col-xs-4 col-lg-4 p-0" >
                                <div className="">
                                    <label className="col-12  mt-4 contactfont" >Contact Information</label><br />
                                    <label className="col-12  mt-4"><h2>For a Product Quote call</h2></label><br />
                                    <label className="col-12  mt-4" style={{ color: "#821523" }}><h1>8056823111</h1></label>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-8 col-xs-12 col-lg-4 p-0 imgbox">
                                <img src={img1} className="backimg" ></img>
                            </div>
                            <div className="col-md-4 col-sm-8 col-xs-12 col-lg-4 whitebox p-0">
                                <div className="col-lg-12 ">
                                    <h4 className="" >Name</h4>
                                    <input className="col-12 form-control form-control-lg" placeholder="Name"></input>
                                </div>
                                <div className="col-lg-12 ">
                                    <h4 className="" >Email</h4>
                                    <input className="col-12  form-control form-control-lg" placeholder="Email"></input>
                                </div>
                                <div className="col-lg-12 ">
                                    <h4 className="" >Message</h4>
                                    <textarea className="col-12 form-control" placeholder="Message..."></textarea>
                                </div>
                                <div className="mt-4 mb-4 justify-content-center d-flex col-lg-12"><button className="col-6 btn btn-lg" style={{ backgroundColor: "#3b3380", color: "white" }} >Send</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 whatwedo row">
                    <div className="weredo col-4">
                        <p>We're <span style={{ color: "#3b3380" }}>Doing</span></p>
                    </div>
                    <div className="rrr scaleincenter col-4">
                        <ScrollAnimation delay={400} animateIn="fadeIn">
                            <img src={rrr} className="rrrimage"></img>
                        </ScrollAnimation>
                    </div>
                    <div className="gogreen col-4">
                        <p>Going green means you have made the conscious decision to not steal from your children</p>
                    </div>
                </div>

            </div>

        )
    }
}
