import React, { Fragment } from "react";
import img1 from '../../img/banner.jpg';
import img2 from '../../img/banner2.jpg';
import ReactSimpleCarousel from "react-plain-carousel";
import ScrollAnimation from 'react-animate-on-scroll';
import Fade from 'react-reveal';

class Carousel extends React.Component {
    state = {
        carousel: {},
        index: 0
    };


    render() {
        return (
            <Fragment>
                <ReactSimpleCarousel isInfinity autoplay > 
                    <div className="carousel">
                        
                        <img alt="" src={img1} />
                        <div className="col-12 style">
                            <label><ScrollAnimation  delay={300} animateIn="bounceInRight" >Customize your bag</ScrollAnimation></label><br/>
                            <small>Contact us for more details</small>
                        </div>
                    </div>
                    {/* <div>
                        <img src={img1}></img>
                        isInfinity autoplay
                    </div> */} 

                    <div  className="carousel">
                        <img alt="" src={img2} />
                        <div className="col-12 style">
                            <label><ScrollAnimation  delay={300} animateIn="bounceInRight" >Exciting deals and offers</ScrollAnimation></label><br/>
                            <small>Contact us for more details</small>
                        </div>
                    </div>
                </ReactSimpleCarousel>
            </Fragment>
        );
    }
}

export default Carousel