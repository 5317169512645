import React, { Component } from 'react';
import './index.css';
import logo from '../../img/logo.png'
import fb from '../../img/sm/f.png';
import insta from '../../img/sm/i.png';
import whatsapp from '../../img/sm/w.png';
// import im from '../../img/imlogo.png'
// import fb from '../../img/sm/f.png';
// import fb from '../../img/sm/f.png';

export default class index extends Component {
    render() {
        return (
            <div>
                <div className="footer">
                    {/* <div className="section">
                        <div className="col-12 mt-4 row">
                            <div className="col-6 " >
                                <img src={logo} className="logo"></img>
                            </div>
                            <div className="col-6 mt-4 row">
                                <div className="footermenu" >
                                    <label className="m-2">Home</label>
                                    <label className="m-2">About Us</label>
                                    <label className="m-2">Products</label>
                                    <label className="m-2">Enquiry</label>
                                    <label className="m-2">Contact</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4 row">
                            <div className="col-6 text-white mt-4" >
                                <i className="fab fa-facebook-square fa-3x"></i>
                                <i className="fab fa-instagram ml-4 fa-3x"></i>
                                <i className="fab fa-whatsapp ml-4 fa-3x"></i>
                            </div>
                            <div className="col-6 mt-4">
                                <p className="copyright">ARC Packs © 2021 All Rights Reserved</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="section">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 flabel m-0 row">
                            <div className="col-xs-6 col-md-6 fc col-lg-3">
                                <center><i className="fal fa-map-marker-alt fa-3x"></i></center>
                                <h4>Reach Us</h4><br />
                                <p className="">9/32, Vasantham Nagar</p><br />
                                <p>Gokulam Colony, Kovaipudur</p>
                            </div>
                            <div className="col-xs-6 fc col-md-6 col-lg-3">
                                <center><i className="fal fa-phone-volume fa-3x"></i></center>
                                <h4>Contact Us</h4><br />
                                <p className="">Phone : 9876543210</p><br />
                                <p>Fax : +9876543210</p>
                            </div>
                            <div className="col-xs-6 fc col-md-6 col-lg-3">
                                <center><i className="fal fa-envelope fa-3x"></i></center>
                                <h4>Mail Us</h4><br />
                                <p className="">example@xyz.com</p><br />
                                <p>hr@example.com</p>
                            </div>
                            <div className="col-xs-6 fc col-md-6 col-lg-3">
                                <center><i className="fal fa-globe fa-3x"></i></center>
                                <h4>Event Details</h4><br />
                                <p className="">Lorem ipsum is simply</p><br />
                                <p>dummy text printing</p>
                            </div>
                        </div>
                    </div>
                    <div className="section2" >
                        <div className="col-12  row">
                            <div className="col-8" >
                                <p className="copyright">ARC Packs © 2021 All Rights Reserved</p>
                            </div>
                            <div className="col-4 icons">
                                <i className="fab fa-whatsapp ml-4 float-right fa-2x"></i>
                                <i className="fab fa-instagram ml-4 float-right fa-2x"></i>
                                <i className="fab fa-facebook-square float-right fa-2x"></i>

                            </div>
                            {/* <div className="col-12 size row">
                                <div className="col-4"></div>
                                <div className="col-4"><center><img className="logo" src={logo}></img></center></div>
                                <div className="col-4"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
